export default {
  testing: 'JS test frameworks',
  'other-languages': 'Other languages',
  more: {
    title: 'More',
  },
  index: {
    display: 'hidden',
  },
}

import meta from "../../../src/pages/_meta.tsx";
import recipes_meta from "../../../src/pages/recipes/_meta.tsx";
import recipes_testing_meta from "../../../src/pages/recipes/testing/_meta.tsx";
export const pageMap = [{
  data: meta
}, {
  name: "advanced",
  route: "/advanced",
  frontMatter: {
    "sidebarTitle": "Advanced"
  }
}, {
  name: "index",
  route: "/",
  frontMatter: {
    "sidebarTitle": "Index"
  }
}, {
  name: "legal",
  route: "/legal",
  children: [{
    name: "contact",
    route: "/legal/contact",
    frontMatter: {
      "sidebarTitle": "Contact"
    }
  }, {
    name: "data-retention",
    route: "/legal/data-retention",
    frontMatter: {
      "sidebarTitle": "Data Retention"
    }
  }, {
    name: "privacy",
    route: "/legal/privacy",
    frontMatter: {
      "sidebarTitle": "Privacy"
    }
  }]
}, {
  name: "not",
  route: "/not",
  frontMatter: {
    "sidebarTitle": "Not"
  }
}, {
  name: "oldrecipes",
  route: "/oldrecipes",
  frontMatter: {
    "sidebarTitle": "Oldrecipes"
  }
}, {
  name: "recipes",
  route: "/recipes",
  children: [{
    data: recipes_meta
  }, {
    name: "badges",
    route: "/recipes/badges",
    frontMatter: {
      "sidebarTitle": "Badges"
    }
  }, {
    name: "index",
    route: "/recipes",
    frontMatter: {
      "sidebarTitle": "Index"
    }
  }, {
    name: "more",
    route: "/recipes/more",
    children: [{
      name: "eslint",
      route: "/recipes/more/eslint",
      frontMatter: {
        "sidebarTitle": "Eslint"
      }
    }, {
      name: "pdf",
      route: "/recipes/more/pdf",
      frontMatter: {
        "sidebarTitle": "Pdf"
      }
    }, {
      name: "website",
      route: "/recipes/more/website",
      frontMatter: {
        "sidebarTitle": "Website"
      }
    }]
  }, {
    name: "more",
    route: "/recipes/more",
    frontMatter: {
      "sidebarTitle": "More"
    }
  }, {
    name: "other-languages",
    route: "/recipes/other-languages",
    children: [{
      name: "go",
      route: "/recipes/other-languages/go",
      frontMatter: {
        "sidebarTitle": "Go"
      }
    }, {
      name: "python",
      route: "/recipes/other-languages/python",
      frontMatter: {
        "sidebarTitle": "Python"
      }
    }]
  }, {
    name: "other-languages",
    route: "/recipes/other-languages",
    frontMatter: {
      "sidebarTitle": "Other Languages"
    }
  }, {
    name: "testing",
    route: "/recipes/testing",
    children: [{
      data: recipes_testing_meta
    }, {
      name: "ava",
      route: "/recipes/testing/ava",
      frontMatter: {
        "sidebarTitle": "Ava"
      }
    }, {
      name: "jest",
      route: "/recipes/testing/jest",
      frontMatter: {
        "sidebarTitle": "Jest"
      }
    }, {
      name: "mocha",
      route: "/recipes/testing/mocha",
      frontMatter: {
        "sidebarTitle": "Mocha"
      }
    }, {
      name: "playwright",
      route: "/recipes/testing/playwright",
      frontMatter: {
        "sidebarTitle": "Playwright"
      }
    }, {
      name: "vitest",
      route: "/recipes/testing/vitest",
      frontMatter: {
        "sidebarTitle": "Vitest"
      }
    }]
  }, {
    name: "testing",
    route: "/recipes/testing",
    frontMatter: {
      "sidebarTitle": "Testing"
    }
  }]
}, {
  name: "self-hosting",
  route: "/self-hosting",
  frontMatter: {
    "sidebarTitle": "Self Hosting"
  }
}];